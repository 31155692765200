import { Route, Redirect } from "react-router-dom";
import UnauthorizedPage from '../../admin/unathorized';

const ProtectedRoute = ({ component: Component, userPermissions = [], allowedPermissions, userRole, ...rest }) => {
    const isAuthenticated = !!localStorage.getItem('auth_token');
    const isAuthorized = userPermissions.some(permission => allowedPermissions.includes(permission));
   
    return (
      <Route
        {...rest}
        render={props => {
          if (!isAuthenticated) {
            // Redirect to login with current location to return after login
            return (
              <Redirect
                to={{
                  pathname: '/auth/login',
                  state: { from: props.location }
                }}
              />
            );
          }
          
          return isAuthorized ? (
            <Component {...props} userRole={userRole} />
          ) : (
            <UnauthorizedPage />
          );
        }}
      />
    );
  };

export default ProtectedRoute;