import {
  SisternodeOutlined,
  BankOutlined,
  DeploymentUnitOutlined,
  SettingOutlined,
  PartitionOutlined,
  WeiboCircleOutlined,
  ApartmentOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  BranchesOutlined,
  AuditOutlined,
  TeamOutlined,
  AccountBookOutlined,
  UserOutlined,
  PullRequestOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  BoxPlotOutlined,
  GroupOutlined,
  FileOutlined,
  ReadOutlined,
  ClusterOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  BookOutlined,
  OneToOneOutlined,
  UserAddOutlined,
  MoneyCollectOutlined,
  PayCircleOutlined,
  UserDeleteOutlined,
  PoundCircleOutlined,
  EnvironmentOutlined,
  AppstoreAddOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  SwitcherOutlined,
  ShoppingOutlined,
  AppstoreOutlined,
  FundOutlined,
  FileTextOutlined,
  DollarOutlined,
  ClockCircleOutlined,
  ScheduleOutlined,
  GoldOutlined,
  FileSearchOutlined,
  CarOutlined,
  InboxOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import isTokenExpired from "hooks/tokenEx";
import jwt from "jwt-decode";

let role = "";
const token = localStorage.getItem("auth_token");
const roles = localStorage.getItem("permissions");
if (token) {
  role = jwt(token).roles;
  isTokenExpired();
}

const ParentAdmindashBoardNavTree = [
  {
    title: "menu.users_management",
    key: "users_management",
    breadcrumb: false,
    submenu: [
      {
        title: "menu.roles",
        path: `${APP_PREFIX_PATH}/roles`,
        icon: SettingOutlined,
        key: "roles",
        permission: "viewRole",
      },
      {
        title: "menu.users",
        path: `${APP_PREFIX_PATH}/users`,
        icon: UserOutlined,
        key: "users",
        permission: "viewUser",
      },
    ],
  },
  {
    title: "menu.project_management_unit",
    key: "project_management_unit",
    breadcrumb: false,
    submenu: [
      {
        title: "menu.opportunity",
        path: `${APP_PREFIX_PATH}/opportunity`,
        icon: SisternodeOutlined,
        key: "opportunity",
        permission: "viewOpportunity",
      },
      {
        title: "menu.project_management",
        path: `${APP_PREFIX_PATH}/projectManagement`,
        icon: DeploymentUnitOutlined,
        key: "projectManagement",
        permission: "viewProject",
      },
      {
        title: "menu.configurations_pmu",
        icon: SettingOutlined,
        key: "configPmu",
        permission: "viewSector",
        submenu: [
          {
            title: "menu.sector",
            path: `${APP_PREFIX_PATH}/sector`,
            key: "sector",
            icon: ApartmentOutlined,
            permission: "viewSector",
          },
          {
            title: "menu.donors",
            path: `${APP_PREFIX_PATH}/donors`,
            key: "donors",
            icon: TeamOutlined,
            permission: "viewDonor",
          },
          {
            title: "menu.people_targeted_categories",
            path: `${APP_PREFIX_PATH}/peopleTargetedCategories`,
            key: "peopleTargetedCategories",
            icon: BranchesOutlined,
            permission: "viewPeopleTargetedCategory",
          },
        ],
      },
    ],
  },
  {
    title: "menu.accounting",
    key: "accounting",
    breadcrumb: false,
    permission: "viewChartOfAccounts",
    submenu: [
      {
        title: "menu.chart_of_accounts",
        path: `${APP_PREFIX_PATH}/chartAccounts`,
        icon: BarChartOutlined,
        key: "chartAccounts",
        permission: "viewChartOfAccounts",
      },
      {
        title: "menu.vouchers",
        path: `${APP_PREFIX_PATH}/vouchers`,
        icon: AuditOutlined,
        key: "vouchers",
        permission: "viewVoucher",
      },
      {
        title: "menu.archive_vouchers",
        path: `${APP_PREFIX_PATH}/archiveVouchers`,
        icon: AuditOutlined,
        key: "archiveVouchers",
        permission: "viewVoucherArchive",
      },
      {
        title: "menu.journal",
        path: `${APP_PREFIX_PATH}/journal`,
        icon: BookOutlined,
        key: "journal",
        permission: "viewJournal",
      },
      {
        title: "menu.ledger",
        path: `${APP_PREFIX_PATH}/ledger`,
        icon: DollarOutlined,
        key: "ledger",
        permission: "viewLedger",
      },
      {
        title: "menu.trial_balance",
        path: `${APP_PREFIX_PATH}/trialbalance`,
        icon: OneToOneOutlined,
        key: "trialbalance",
        permission: "viewTrialBalance",
      },
      {
        title: "menu.exchangeGainLoss",
        path: `${APP_PREFIX_PATH}/exchangeGainLoss`,
        icon: OneToOneOutlined,
        key: "exchangeGainLoss",
        permission: "viewGainLoss",
      },
      {
        title: "menu.payroll_voucher_admin",
        path: `${APP_PREFIX_PATH}/accounting/payroll`,
        icon: PayCircleOutlined,
        key: "acc_payroll",
        permission: "viewFinancePendingPayroll",
      },
      {
        title: "menu.exchange_rates",
        path: `${APP_PREFIX_PATH}/exchangeRate/office`,
        icon: MoneyCollectOutlined,
        key: "exchangeRates",
        permission: "viewExchangeRates",
      },
      {
        title: "menu.accounting_requests",
        path: `${APP_PREFIX_PATH}/accountingRequests`,
        key: "accountingRequests",
        permission: "viewAccountingRequest",
        icon: PullRequestOutlined,
      },
      {
        title: "menu.financial_reports",
        path: `${APP_PREFIX_PATH}/notes`,
        key: "notes",
        icon: FileTextOutlined,
        permission: "viewNotes",
      },
      // {
      //   title: "menu.financial_reports",
      //   icon: ReconciliationOutlined,
      //   key: "financialReports",
      //   permission: "viewBalanceSheet",
      //   submenu: [
      //     {
      //       title: "menu.balance_sheet",
      //       path: `${APP_PREFIX_PATH}/BalanceSheet`,
      //       key: "BalanceSheet",
      //       icon: ProfileOutlined,
      //       permission: "viewBalanceSheet",
      //     },
      //     {
      //       title: "menu.income_expense_report",
      //       path: `${APP_PREFIX_PATH}/incomeExpenseReport`,
      //       key: "incomeExpenseReport",
      //       icon: FundOutlined,
      //       permission: "viewIncomeAndExpenseReport",
      //     },
      //     {
      //       title: "menu.notes",
      //       path: `${APP_PREFIX_PATH}/notes`,
      //       key: "notes",
      //       icon: FileTextOutlined,
      //       permission: "viewNotes",
      //     },
      //   ],
      // },
      {
        title: "menu.configurations_accounting",
        icon: SettingOutlined,
        key: "configAccounting",
        permission: "viewFinancialYear",
        submenu: [
          {
            title: "menu.setting_financial_year",
            path: `${APP_PREFIX_PATH}/financialYear`,
            key: "financialYear",
            icon: ClusterOutlined,
            permission: "viewFinancialYear",
          },
          // {
          //   title: "menu.gain_loss_account",
          //   path: `${APP_PREFIX_PATH}/exchangeGainLoss`,
          //   key: "exchangeGainLoss",
          //   icon: SolutionOutlined,
          //   permission: "viewExchangeGainAndLossAccount",
          // },
          {
            title: "menu.office",
            path: `${APP_PREFIX_PATH}/office`,
            key: "office",
            icon: BankOutlined,
            permission: "viewOffice",
          },
          {
            title: "menu.currency",
            path: `${APP_PREFIX_PATH}/currency`,
            key: "currency",
            icon: DollarCircleOutlined,
            permission: "viewCurrency",
          },
          {
            title: "menu.bank",
            path: `${APP_PREFIX_PATH}/bank`,
            key: "bank",
            icon: BankOutlined,
            permission: "viewBank",
          },
          {
            title: "menu.bank_account",
            path: `${APP_PREFIX_PATH}/bankAccount`,
            key: "bankAccount",
            icon: AccountBookOutlined,
            permission: "viewBankAccount",
          },
          {
            title: "menu.supplier",
            path: `${APP_PREFIX_PATH}/supplier`,
            icon: ShopOutlined,
            key: "supplier",
            permission: "viewSupplier",
          },
          {
            title: "menu.voucher_signature",
            path: `${APP_PREFIX_PATH}/signature`,
            icon: WeiboCircleOutlined,
            key: "signature",
            permission: "viewVoucherSignature",
          },
        ],
      },
    ],
  },
  {
    title: "menu.human_resources",
    key: "hr",
    breadcrumb: false,
    permission: "viewEmployee",
    submenu: [
      {
        title: "menu.prospective_employee",
        path: `${APP_PREFIX_PATH}/prospective_employee`,
        icon: UserAddOutlined,
        permission: "viewProspectiveEmployee",
        key: "prosemployee",
      },
      {
        title: "menu.employee",
        path: `${APP_PREFIX_PATH}/employee`,
        icon: UserOutlined,
        permission: "viewEmployee",
        key: "employee",
      },
      {
        title: "menu.attendance",
        path: `${APP_PREFIX_PATH}/attendance`,
        icon: ScheduleOutlined,
        permission: "viewPendingAttendance",
        key: "attendance",
      },
      {
        title: "menu.payroll",
        path: `${APP_PREFIX_PATH}/payroll`,
        icon: DollarOutlined,
        permission: "viewPendingPayroll",
        key: "payroll",
      },
      {
        title: "menu.payroll_exchange_rate",
        path: `${APP_PREFIX_PATH}/payroll_exchange_rate/office`,
        icon: MoneyCollectOutlined,
        permission: "viewPayrollExchangeRates",
        key: "payexchangerate",
      },
      {
        title: "menu.recruitment",
        path: `${APP_PREFIX_PATH}/recruitment`,
        icon: FileSearchOutlined,
        permission: "viewRecruitment",
        key: "recruitment",
      },
      {
        title: "menu.configurations_hr",
        icon: SettingOutlined,
        permission: "viewGrade",
        key: "configHr",
        submenu: [
          {
            title: "menu.employee_grade",
            path: `${APP_PREFIX_PATH}/employeeGrade`,
            key: "employeeGrade",
            permission: "viewGrade",
            icon: ClusterOutlined,
          },
          {
            title: "menu.designation",
            path: `${APP_PREFIX_PATH}/designation`,
            key: "designation",
            permission: "viewDesignation",
            icon: SolutionOutlined,
          },
          {
            title: "menu.profession",
            path: `${APP_PREFIX_PATH}/profession`,
            key: "profession",
            permission: "viewProfession",
            icon: UserOutlined,
          },
          {
            title: "menu.field_of_study",
            path: `${APP_PREFIX_PATH}/fieldOfStudy`,
            key: "fieldOfStudy",
            permission: "viewFieldOfStudy",
            icon: ReadOutlined,
          },
          {
            title: "menu.degree",
            path: `${APP_PREFIX_PATH}/degree`,
            key: "degree",
            permission: "viewDegree",
            icon: FileProtectOutlined,
          },
          {
            title: "menu.document_type",
            path: `${APP_PREFIX_PATH}/documentType`,
            key: "documentType",
            permission: "viewDocumentType",
            icon: FileOutlined,
          },
          {
            title: "menu.leave",
            path: `${APP_PREFIX_PATH}/leave`,
            key: "leave",
            permission: "viewLeave",
            icon: UserDeleteOutlined,
          },
          {
            title: "menu.working_hours",
            path: `${APP_PREFIX_PATH}/workingHours`,
            key: "workingHours",
            permission: "viewWorkingHour",
            icon: ClockCircleOutlined,
          },
          {
            title: "menu.category",
            path: `${APP_PREFIX_PATH}/category`,
            key: "category",
            permission: "viewCategory",
            icon: GoldOutlined,
          },
          {
            title: "menu.employment_type",
            path: `${APP_PREFIX_PATH}/employmentType`,
            key: "employmentType",
            permission: "viewEmploymentType",
            icon: PartitionOutlined,
          },
        ],
      },
    ],
  },
  {
    title: "menu.procurement",
    key: "procurement",
    breadcrumb: false,
    permission: "viewVendor",
    submenu: [
      {
        title: "menu.requests",
        path: `${APP_PREFIX_PATH}/requests`,
        key: "requests",
        permission: "viewRequest",
        icon: PullRequestOutlined,
      },
      {
        title: "menu.purchaseOrders",
        path: `${APP_PREFIX_PATH}/purchaseOrders`,
        key: "purchaseOrders",
        permission: "viewOrders",
        icon: ShoppingCartOutlined,
      },
      {
        title: "menu.vendors",
        path: `${APP_PREFIX_PATH}/vendors`,
        key: "vendors",
        permission: "viewVendor",
        icon: ShopOutlined,
      },
      {
        title: "menu.configurations_procurement",
        icon: SettingOutlined,
        permission: "viewVendorCategory",
        key: "configVendors",
        submenu: [
          {
            title: "menu.vendor_category",
            path: `${APP_PREFIX_PATH}/vendors_category`,
            key: "vendorscate",
            permission: "viewVendorCategory",
            icon: ShopOutlined,
          },
          {
            title: "menu.document_type",
            path: `${APP_PREFIX_PATH}/document_type`,
            key: "docType",
            permission: "viewVendorDocumentType",
            icon: FileOutlined,
          },
        ],
      },
    ],
  },
  {
    title: "menu.inventory",
    key: "inventory",
    breadcrumb: false,
    permission: "viewInventory",
    submenu: [
      {
        title: "menu.goodsReceiptNotes",
        icon: FormOutlined,
        key: "goodsReceiptNotes",
        permission: "viewGoodsReceiptNote",
        submenu: [
          {
            title: "menu.ConsumableGrn",
            path: `${APP_PREFIX_PATH}/consumableGrn`,
            key: "consumableGrn",
            permission: "viewConsumableGoodsReceiptNote",
            icon: FormOutlined,
          },
          {
            title: "menu.expendableGrn",
            path: `${APP_PREFIX_PATH}/expendableGrn`,
            key: "expendableGrn",
            permission: "viewExpendableGoodsReceiptNote",
            icon: FormOutlined,
          },
          {
            title: "menu.nonExpendableGrn",
            path: `${APP_PREFIX_PATH}/nonExpendableGrn`,
            key: "nonExpendableGrn",
            permission: "viewNonExpendableGoodsReceiptNote",
            icon: FormOutlined,
          },
        ],
      },
      {
        title: "menu.goodsIssuanceNotes",
        icon: FormOutlined,
        key: "goodsIssuanceNotes",
        permission: "viewGoodsIssuanceNote",
        submenu: [
          {
            title: "menu.consumableGin",
            path: `${APP_PREFIX_PATH}/consumableGin`,
            key: "consumableGin",
            permission: "viewConsumableGoodsIssuanceNote",
            icon: FormOutlined,
          },
          {
            title: "menu.expendableGin",
            path: `${APP_PREFIX_PATH}/expendableGin`,
            key: "expendableGin",
            permission: "viewExpendableGoodsIssuanceNote",
            icon: FormOutlined,
          },
          {
            title: "menu.nonExpendableGin",
            path: `${APP_PREFIX_PATH}/nonExpendableGin`,
            key: "nonExpendableGin",
            permission: "viewNonExpendableGoodsIssuanceNote",
            icon: FormOutlined,
          },
        ],
      },
      {
        title: "menu.consumable_items",
        icon: AppstoreOutlined,
        key: "consumableItems",
        permission: "view-ConsumableItems",
        submenu: [
          {
            title: "menu.master_store_item",
            path: `${APP_PREFIX_PATH}/masterStoreItem`,
            key: "masterStoreItem",
            permission: "viewMasterStoreItem",
            icon: ShopOutlined,
          },
          {
            title: "menu.store_items",
            path: `${APP_PREFIX_PATH}/storeItems`,
            key: "storeItems",
            permission: "viewStoreItem",
            icon: ShoppingOutlined,
          },
        ],
      },
      {
        title: "menu.expendable_item",
        icon: AppstoreAddOutlined,
        permission: "view-ExpendableItems",
        key: "expandableItems",
        submenu: [
          {
            title: "menu.master_store_item",
            path: `${APP_PREFIX_PATH}/expendableMasterStoreItem`,
            key: "expendableMasterStoreItem",
            permission: "viewExpendableMasterStoreItem",
            icon: ShopOutlined,
          },
          {
            title: "menu.store_item_names",
            path: `${APP_PREFIX_PATH}/expendableStoreItemNames`,
            key: "expendableStoreItemNames",
            permission: "viewExpendableStoreItemNames",
            icon: BoxPlotOutlined,
          },
          {
            title: "menu.store_items",
            path: `${APP_PREFIX_PATH}/expendableStoreItems`,
            key: "expendableStoreItems",
            permission: "viewExpendableExistenceStoreItem",
            icon: ShoppingOutlined,
          },
        ],
      },
      {
        title: "menu.non_expendable_item",
        icon: AppstoreAddOutlined,

        permission: "view-NonExpendableItems",
        key: "nonExpandableItems",
        submenu: [
          {
            title: "menu.master_store_item",
            path: `${APP_PREFIX_PATH}/nonExpMasterStoreItem`,
            key: "nonExpMasterStoreItem",
            permission: "viewNonExpendableMasterStoreItem",
            icon: ShopOutlined,
          },
          {
            title: "menu.store_item_names",
            path: `${APP_PREFIX_PATH}/nonExpendableStoreItemNames`,
            key: "nonExpendableStoreItemNames",
            permission: "viewNonExpendableStoreItemNames",
            icon: BoxPlotOutlined,
          },
          {
            title: "menu.store_items",
            path: `${APP_PREFIX_PATH}/nExStoreDetails`,
            key: "nExStoreDetails",
            permission: "viewNonExpendableExistenceStoreItem",
            icon: ShoppingOutlined,
          },
        ],
      },
      {
        title: "menu.receive_inventory_items",
        path: `${APP_PREFIX_PATH}/receiveInventoryItems`,
        icon: InboxOutlined,
        permission: "viewReceiveInventoryItems",
        key: "receiveInventoryItems",
      },
      {
        title: "menu.general_inventory_items",
        path: `${APP_PREFIX_PATH}/empIssuenceReg`,
        icon: PoundCircleOutlined,
        permission: "viewGeneralInventoryItems",
        key: "empIssuenceReg",
      },
      {
        title: "menu.inventory_requests",
        path: `${APP_PREFIX_PATH}/inventoryRequests`,
        key: "inventoryRequests",
        permission: "viewInventoryRequest",
        icon: PullRequestOutlined,
      },
      {
        title: "menu.configurations_inventory",
        icon: SettingOutlined,
        permission: "viewUnit",
        key: "configInventory",
        submenu: [
          {
            title: "menu.unit",
            path: `${APP_PREFIX_PATH}/unit`,
            icon: PoundCircleOutlined,
            permission: "viewUnit",
            key: "unit",
          },
          {
            title: "menu.asset_type",
            path: `${APP_PREFIX_PATH}/assetType`,
            icon: ReconciliationOutlined,
            permission: "viewAssetType",
            key: "assetType",
          },
          {
            title: "menu.receipt_type",
            path: `${APP_PREFIX_PATH}/receiptType`,
            icon: ProfileOutlined,
            permission: "viewReceiptType",
            key: "receiptType",
          },
          {
            title: "menu.depreciation",
            path: `${APP_PREFIX_PATH}/depreciation`,
            icon: SwitcherOutlined,
            permission: "viewDepreciation",
            key: "depreciation",
          },
          {
            title: "menu.status",
            path: `${APP_PREFIX_PATH}/status`,
            icon: PoundCircleOutlined,
            permission: "viewStatus",
            key: "status",
          },
          {
            title: "menu.grn_signature",
            path: `${APP_PREFIX_PATH}/grnSignature`,
            icon: WeiboCircleOutlined,
            permission: "viewGrnSignature",
            key: "grnSignature",
          },
          {
            title: "menu.gin_signature",
            path: `${APP_PREFIX_PATH}/ginSignature`,
            icon: WeiboCircleOutlined,
            permission: "viewGinSignature",
            key: "ginSignature",
          },
        ],
      },
    ],
  },
  {
    title: "menu.supply_chain",
    icon: SettingOutlined,
    permission: "viewLanguages",
    key: "supplychain",
    submenu: [
      {
        title: "menu.rawMatrerials",
        path: `${APP_PREFIX_PATH}/rawmaterials`,
        key: "rawmaterials",
        permission: "viewRawMaterials",
        icon: ShopOutlined,
      },
      {
        title: "menu.shipments",
        path: `${APP_PREFIX_PATH}/shipments`,
        key: "shipments",
        permission: "viewSupplyShippments",
        icon: CarOutlined,
      },
      {
        title: "menu.products",
        path: `${APP_PREFIX_PATH}/products`,
        key: "products",
        permission: "viewSupplyProducts",
        icon: ShopOutlined,
      },
      {
        title: "menu.distribution",
        path: `${APP_PREFIX_PATH}/distribution`,
        key: "distribution",
        permission: "viewLanguages",
        icon: ShopOutlined,
      },
      {
        title: "menu.sales",
        path: `${APP_PREFIX_PATH}/sales`,
        key: "sales",
        permission: "viewLanguages",
        icon: ShopOutlined,
      },
      {
        title: "menu.configurations_supply_chain",
        icon: SettingOutlined,
        permission: "viewItems",
        key: "configSupplyChain",
        submenu: [
          {
            title: "menu.items",
            path: `${APP_PREFIX_PATH}/Items`,
            key: "items",
            permission: "viewItems",
            icon: ShopOutlined,
          },
          {
            title: "menu.grade",
            path: `${APP_PREFIX_PATH}/supplygrade`,
            key: "supplygrade",
            permission: "viewSupplyChainGrade",
            icon: ShopOutlined,
          },
          {
            title: "menu.supplier",
            path: `${APP_PREFIX_PATH}/supplychainsupplier`,
            key: "supplier",
            permission: "viewSupplyChainSupplier",
            icon: ShopOutlined,
          },
          {
            title: "menu.itemqrcode",
            path: `${APP_PREFIX_PATH}/supplychainitemqrcode`,
            key: "itemqrcode",
            permission: "viewSupplyChainItemsQrcode",
            icon: ShopOutlined,
          },
          {
            title: "menu.productDesign",
            path: `${APP_PREFIX_PATH}/supplychainprodcutDesign`,
            key: "productdesign",
            permission: "viewSupplyChainProductDesign",
            icon: ShopOutlined,
          },
        ],
      },
    ],
  },
  {
    title: "menu.system_configuration",
    icon: SettingOutlined,
    permission: "viewLanguages",
    key: "systemconfiguration",
    submenu: [
      {
        title: "menu.languages",
        path: `${APP_PREFIX_PATH}/languages`,
        key: "languages",
        permission: "viewLanguages",
        icon: ShopOutlined,
      },
    ],
  },
];

const userPermissions = roles;

let navigationConfig = [];

const filteredMenu = ParentAdmindashBoardNavTree.filter((menu) => {
  // Check if the menu item has submenus
  const hasSubmenu = menu?.submenu?.some((item) =>
    userPermissions?.includes(item.permission)
  );

  if (hasSubmenu) {
    // If the menu item has submenus, remove the menu title if user doesn't have permission
    menu.submenu = menu?.submenu?.filter((item) =>
      userPermissions?.includes(item.permission)
    );
    return true;
  }

  // Check if the menu item itself has permission
  if (menu.permission && userPermissions?.includes(menu.permission)) {
    return true;
  }

  return false;
});

navigationConfig = [...filteredMenu, role];

export default navigationConfig;
